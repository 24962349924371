<template>
    <div>
    <div style="margin:5px" >
        <div style="padding-top:4px">
            <a-radio-group @change="query" v-model:value="shopId" name="radioGroup">
                <a-radio v-for="v in shopList" :key="v.Id" :value="v.Id">{{ v.Name }}</a-radio>
            </a-radio-group>
        </div>
        <input id="inputCopy" value="" style="opacity:0;position:absolute" />
        <div>
            <a-table :loading="loading"  rowKey="ShopId"   :pagination="false" :dataSource="list" :columns="columns" >
                <template #accountName="{ text }">
                    <a-tag style="cursor: pointer;" @click="copy(text)" color="#2db7f5" > <copy-outlined /> &nbsp;{{text}}</a-tag>
                </template>
                <template #orderCode="{ text }">
                    <a style="color:blue" @click="copy(text)"><copy-outlined /> {{text}}</a>
                </template>
            </a-table>
        </div>

    </div>
    </div>
</template>
<script>
import {  ref } from 'vue';
import { taskGetReceiptShopReq,taskGetReceiptOrderReq } from '@/request.js';


// import { useRouter } from 'vue-router';
// import { message } from 'ant-design-vue';
import { CopyOutlined } from '@ant-design/icons-vue';
import { message } from 'ant-design-vue';

export default {
    components: {
        CopyOutlined,
    },
    setup(){
        let queryToken="";
        if(window.location.hash&&window.location.hash.indexOf('?')>-1){
                let array=window.location.hash.split('?')[1].split('&');
                array.forEach(x=>{
                    let kv= x.split('=');
                    if(kv[0]=="queryToken"){
                        queryToken=kv[1];
                        console.log(queryToken);
                    }
                })
        }
        const loading=ref(false);
        const list=ref([]);
        const shopId=ref();
        const shopList=ref([]);
        const query=()=>{
            loading.value=true;
            taskGetReceiptOrderReq({token:queryToken,shopId:shopId.value},(res)=>{
                list.value=res.Result;
                console.log(res);
                loading.value=false;
            })
        };

        taskGetReceiptShopReq({token:queryToken},(res)=>{
            shopList.value=res.Result;
            if(shopList.value.length>0){
                shopId.value=shopList.value[0].Id;
                query();
            }
        })
        const copy=(text)=>{
            const input = document.getElementById('inputCopy');
            input.value = text; 
            input.select(); // 选中文本
            document.execCommand('copy');
            message.success("复制成功");
        }
        return{
            copy,
            shopId,
            shopList,
            loading,
            list,
            query,
            columns: [
                {
                    title: '订单编号(点击复制)',
                    dataIndex: 'OrderCode',
                    key: 'orderCode',
                    slots: { customRender: 'orderCode' },
                },
                {
                    title: '旺旺号(点击复制)',
                    dataIndex: 'AccountName',
                    key: 'accountName',
                    slots: { customRender: 'accountName' },
                },
            ],
        }
    }
}
</script>
